import React, {FC, useMemo} from 'react';
import {label} from "../../../service";
import {SelectedBook} from "../Checkout";
import "./index.scss";
import {clsx} from "clsx";
import {dateToTs, durFromTs} from "../date_picker/utils";
import {CalType} from "../../../page/Bike";
import {RegularTime} from "./RegularTime";
import {BikeDetail} from "../../../types";
import {SetT} from "../../../hooks/use-map";
import {isWorkTime} from "../../../utils";

export const SelectTime:FC<{
    date:SelectedBook,
    isSelectedOption:boolean,
    setDate: (i:SelectedBook)=>void,
    setISelectedOption:(result:boolean)=>void,
    detail:BikeDetail
    type?:CalType,
    overtime:SetT<string>

     }> = ({date,
                                       isSelectedOption,
                                       setDate,setISelectedOption,type,
                                       detail,overtime}) => {

    const nextHandler = ()=>{
       setISelectedOption(true);
    }


    const timeSelectHandler =(e:React.ChangeEvent<HTMLSelectElement>)=>{

        // console.log(e.target.name,e.target.value )
        if(e.target.value==="") {
            return;
        }

            const  tDate = {...date}  ;
            // @ts-ignore
            tDate[e.target.name] = e.target.value;
            if(e.target.name === 'optionStart'){
                tDate.optionEnd = date.end==date.start?'': e.target.value;

            }

        if(!isWorkTime(tDate.start,tDate.optionStart,detail.schedule)){
            if(!overtime.has('pickup')){
                overtime.add('pickup');

            }
        }else{
            if(overtime.has('pickup')){
                overtime.delete('pickup')
            }
        }

            if(!isWorkTime(tDate.end,tDate.optionEnd,detail.schedule)){
                if(!overtime.has('drop_off')){
                    overtime.add('drop_off');

                }
            }else{
                if(overtime.has('drop_off')){
                    overtime.delete('drop_off')
                }
            }


            tDate.duration = durFromTs(dateToTs(tDate.start,tDate.optionStart),dateToTs(tDate.end,tDate.optionEnd));
            setDate(tDate);

    }

   const half:1|2|undefined  = useMemo(()=>{
       if(date.optionStart =="08:30" && date.optionEnd=="12:30"){
           return  1;
       }
       if(date.optionStart =="12:30" && date.optionEnd=="16:30"){
           return  2;
       }
       return undefined;
   },[date,type])

    const select1th =()=>{
        const tDate = {...date,optionStart:"08:30",optionEnd:"12:30"};
        tDate.duration = durFromTs(dateToTs(date.start,date.optionStart),dateToTs(date.end,date.optionEnd));
        setDate(tDate);
        setISelectedOption(true);
        //setHalf(1)
    }
    const select2nd =()=>{
        const tDate = {...date,optionStart:"12:30",optionEnd:"16:30"};
        tDate.duration = durFromTs(dateToTs(date.start,date.optionStart),dateToTs(date.end,date.optionEnd));
        setDate(tDate);
        setISelectedOption(true);
       // setHalf(2);
    }

    const isDaysSelect= useMemo(()=>{

        return  date.start !== '' && date.end !== '';
    },[date]);

    const isNextAv =useMemo(()=>{

        if(type==="half"){
            return  !!half;
        }

       if(date.optionEnd!=="" && date.optionStart!==""){
           return isDaysSelect;

     /*       const strDateStart = `${date.start.split('.').reverse().join('-')} ${date.optionStart}`;
            const strDateEnd = `${date.end.split('.').reverse().join('-')} ${date.optionEnd}`;
            return Date.parse(strDateStart)<Date.parse(strDateEnd);*/
        }
       return  false

    },[isDaysSelect,half,date]);


    const render =()=>{
        switch (type){
            case 'half':
                return (
                    <div className={"select-type-layer"} >
                        <div className={"btn-panel"}>
                            <div onClick={select1th} className={clsx("btn-more",half===1?"active":"")}>{label('1st half')}</div>
                            <div onClick={select2nd} className={clsx("btn-more",half===2?"active":"")}>{label('2nd half')}</div>
                        </div>
                    </div>
                )
            default:
                return  (
                      <RegularTime
                            schedule={detail.schedule}
                            intervals={detail.intervals}
                            timeSelectHandler={timeSelectHandler}
                            date={date}
                      />
                )
        }
    }

    return (
        <div className={"bike-details_checkout_layer " + (isSelectedOption ? "disable_layer2" : "")}>
            {!isDaysSelect && <h2>{label('Please choose date to continue book motorcycle')}</h2>}
            {isDaysSelect && <div className={"time_selector"}>
                {render()}
                <div
                    className={clsx(
                        "booking-block",
                        !isNextAv && 'invisible',)
                    }>
                    <div
                        className={"btn  " + " btn-secondary"}
                        onClick={nextHandler}
                    >{label('Next')}</div>
                </div>
            </div>}
        </div>
    );
};
