import React, {FC, useMemo} from 'react';
import {Interval, Schedule} from "../../../types";
import {
    getDayByDateString,
    getDaySchedule,
    getRuleInterval,
    isNotAvailable,
    isWorkDay,
    isWorkTime
} from "../../../utils";
import {clsx} from "clsx";

const timeOption = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'

];

export const TimeSelectRow: FC<{
    label: string, day: string, value: string,
    name: string,
    schedule: Schedule[],
    intervals: Interval[],
    onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void
    filter?: string | undefined
}> =
    ({label, day, value, name, onSelect, schedule, intervals,filter}) => {

        const not_work_day = useMemo(() => {
            return !isWorkDay(day, schedule)
        }, [day]);

        const availTimeOption = () => {
            const weekDay = getDayByDateString(day);
            const scheduleDay: Schedule | null = getDaySchedule(weekDay, schedule);
            const interval: Interval | null = getRuleInterval(day, intervals);

            if (interval) {
                return timeOption.filter((time)=>
                                    interval.work_day &&
                                    time>=interval.time_min &&
                                    time<=interval.time_max);
            }
            if (scheduleDay === null) {
                return timeOption;
            }
            // @ts-ignore
            if (scheduleDay && (!scheduleDay.time_min || !scheduleDay.time_max)) {
                return timeOption;
            }
            // @ts-ignore
            let mins = scheduleDay.time_min.split(':');
            let min = mins[0] ?? 0;
            // @ts-ignore
            let maxs = scheduleDay.time_max.split(':');
            let max = maxs[0] ?? 23;

            let options: string[] = [];
            timeOption.forEach(time => {
                if (+time >= +min && +time <= +max) {
                    options.push(time);
                }
            });
            return options;
        };

        const availTime = availTimeOption();

        const timeMap: { name: string, not_work: boolean, not_available: boolean }[] = useMemo(() => {
            const res = availTime.map((item) => {
                let not_work = !isWorkTime(day, item, schedule);
                let not_available = isNotAvailable(day, item);
                return {name: item, not_work: not_work, not_available}
            });
            if (filter === undefined) {
                return res;
            }
            return res.filter(item => {
                return item.name.split(":")[0] > filter.split(":")[0]
            });
        }, [day, filter]);


        const not_work_time = useMemo(() => {
            return !isWorkTime(day, value, schedule);
        }, [day, value])

        return <div className="time-s-row">
            <span className="time-label">{label}</span>
            <span className={clsx("time-day", not_work_day && 'not-work')}>{day} {not_work_day && '*'}</span>
            <select
                className={clsx(not_work_time && "not-work")}
                name={name} value={value} onChange={onSelect}>
                <option key={0} value={""}>{`...`}</option>
                {
                    timeMap.map((item) => {
                        if (item.not_available) {
                            return null;
                        }
                        return <option
                                    className={clsx(item.not_work && 'not-work')}
                                    key={item.name}
                                    value={`${item.name}:00`}
                                >
                                    {`${item.name}:00`}
                                </option>
                    })
                }


            </select>
        </div>
    }

