import React, {useMemo} from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {label} from "../../../service";
import {clsx} from "clsx";
import {changeDate} from "../../../features/cart/cartSlice";
import {BikeCheckoutRegularTime} from "./BikeCheckoutRegularTime";
import {setStage} from "../../../features/bike/bikeSlice";
import {Interval} from "../../../types";

export const BikeCheckoutTime = () => {
    const start_1half = "08:30";
    const end_1half = "12:30";
    const start_2half = "12:30";
    const end_2half = "16:30";
    const date = useAppSelector(state => state.cart.values.date);
    const type = useAppSelector(state => state.cart.values.type);
    const schedule = useAppSelector(state => state.bikes.detail?.schedule||[]);
    const intervals = useAppSelector(state => state.bikes.detail?.intervals||[]);
    const overtime = useAppSelector(state => state.cart.values.overtime);

    const dispatch = useAppDispatch();

    const isDaysSelect = useMemo(() => {
        return date.start !== '' && date.end !== '';
    }, [date]);

    const half: 1 | 2 | undefined = useMemo(() => {
        if (date.optionStart == start_1half && date.optionEnd == end_1half) {
            return 1;
        }
        if (date.optionStart == start_2half && date.optionEnd == end_2half) {
            return 2;
        }
        return undefined;
    }, [date, type]);

    const select1th = () => {
        const new_date = {...date, optionStart: start_1half, optionEnd: end_1half};
        dispatch(setStage({stage:1}));
        dispatch(changeDate(
            {date:new_date,stage:1}
        ));
    }

    const select2nd = () => {
        const new_date = {...date, optionStart: start_2half, optionEnd: end_2half};
        dispatch(setStage({stage:1}));
        dispatch(changeDate(
            {date:new_date,stage:1}
        ));
    }

    const timeSelectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        // console.log(e.target.name,e.target.value )
        if (e.target.value === "") {
            return;
        }
        const tDate = {...date};
        // @ts-ignore
        tDate[e.target.name] = e.target.value;
        if (e.target.name === 'optionStart') {
            tDate.optionEnd = date.end == date.start ? '' : e.target.value;
        }
        dispatch(changeDate({date:tDate,stage:0}));
    }

    const canSelect = (date:string,timeStart:string,timeEnd:string,intervals:Interval[]) => {
        const date_arr = date.split(".");
        const my_date = date_arr[2]+'-'+date_arr[1]+'-'+date_arr[0];
        const interval = [...intervals].reverse().find(item => my_date>=item.date_from && my_date<=item.date_to) || null;
        if (interval) {
            return (timeStart.substring(0,2)>=interval.time_min && timeEnd.substring(0,2)<=interval.time_max);
        }
        return true;
    }

    const render = () => {
        switch (type) {
            case 'half':
                return (
                    <div className={"select-type-layer"}>
                        <div className={"btn-panel"}>
                            {canSelect(date.start,start_1half,end_1half,intervals) &&
                                <div onClick={select1th}
                                     className={clsx("btn-more", half === 1 ? "active" : "")}
                                >
                                    {label('1st half')}
                                </div>}
                            {canSelect(date.start,start_2half,end_2half,intervals) &&
                                <div onClick={select2nd}
                                      className={clsx("btn-more", half === 2 ? "active" : "")}
                                >
                                    {label('2nd half')}
                                </div>}
                        </div>
                    </div>
                )
            case 'weekend':
                return null;
            default:
                return (
                    <BikeCheckoutRegularTime
                        date={date}
                        timeSelectHandler={timeSelectHandler}
                        schedule={schedule}
                        intervals={intervals}
                        overtime={overtime}
                    />
                )
        }
    }

    return (
        <div className={"bike_checkout_layer "}>
            {!isDaysSelect && <h2>{label('Please choose date to continue book motorcycle')}</h2>}
            {isDaysSelect && <div className={"time_selector"}>
                {render()}
            </div>}
        </div>
    )
}